// src/App.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HeaderTemp from './components/HeaderTemp/HeaderTemp';
import SplashImageTemp from './components/SplashImageTemp/SplashImageTemp';
import Footer from './components/Footer/Footer';

function App() {
  return (
    <Router>
      <HeaderTemp />
      <Routes>
        <Route
          path="/"
          element={
            <>
              <SplashImageTemp />
            </>
          }
        />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
